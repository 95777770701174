import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useSelector } from "react-redux";
import FavoriteIcon from "@mui/icons-material/Favorite";
import "../Footer/Footer.css";

const Footer = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <div className="footer-logo">
              Malsawma<span>Store</span>
            </div>
            <span>
              Made with <FavoriteIcon className="text-danger" /> in India
            </span>
            <br />
            <span>+91 8798040381</span>
            <br />
            <span>malsawmagamingstore@gmail.com</span>
            <br />
            <span>India</span>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Quick Links</h6>
            <ul>
              {!user ? (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              ) : (
                <li>
                  <Link to="/user-dashboard">Dashboard</Link>
                </li>
              )}
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>Connect here</h6>
            <div className="social-media-links">
              {/* <Link
                target="_blank"
                to="https://www.instagram.com/aks_store_2022"
              >
                <InstagramIcon className="icon" />
              </Link> */}
              {/* <Link target="_blank" to="">
                <FacebookIcon className="icon" />
              </Link> */}
              <Link target="_blank" to="https://wa.me/918798040381">
                <WhatsAppIcon className="icon" />
              </Link>
              <Link target="_blank" to="mailto:malsawmagamingstore@gmail.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
          </div>

          <hr />
          <span className="text-center">
            <small>
              All Rights Reserved © 2024 | MALSAWMA STORE |{" "}
              <br className="d-block d-md-none d-lg-none" />
              Website designed & developed by{" "}
              <Link
                target="_blank"
                style={{ color: "var(--t)", fontWeight: "500" }}
                to="https://aashirdigital.com/"
              >
                ~@aashirdigital
              </Link>
            </small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
